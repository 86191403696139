import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
class NgxPasswordStrengthBarComponent {
  static measureStrength(pass) {
    let score = 0;
    // award every unique letter until 5 repetitions
    const letters = {};
    for (const element of pass) {
      letters[element] = (letters[element] || 0) + 1;
      score += 5.0 / letters[element];
    }
    // bonus points for mixing it up
    const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass)
    };
    let variationCount = 0;
    for (let check in variations) {
      variationCount += variations[check] ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    return Math.trunc(score);
  }
  constructor() {
    this.onStrengthChanged = new EventEmitter();
    this.defaultColors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];
    this.defaultThresholds = [90, 70, 40, 20];
    this.defaultBaseColor = '#DDD';
    this.colors = this.defaultColors;
    this.thresholds = this.defaultThresholds;
  }
  checkBarColors() {
    // Accept custom colors if input is valid, otherwise the default colors will be used
    if (this.barColors && this.barColors.length === 5) {
      this.colors = this.barColors.slice();
    } else {
      this.colors = this.defaultColors;
    }
    this.strengths = this.strengthLabels && this.strengthLabels.length === 5 ? this.strengthLabels.slice() : null;
    this.setStrengthLabel(0);
    if (!/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(this.baseColor)) {
      this.baseColor = this.defaultBaseColor;
    }
  }
  checkThresholds() {
    if (this.customThresholds && this.customThresholds.length === 4) {
      this.thresholds = this.customThresholds.slice();
    } else {
      this.thresholds = this.defaultThresholds;
    }
  }
  getColor(score) {
    let idx = 0;
    if (score > this.thresholds[0]) {
      idx = 4;
    } else if (score > this.thresholds[1]) {
      idx = 3;
    } else if (score >= this.thresholds[2]) {
      idx = 2;
    } else if (score >= this.thresholds[3]) {
      idx = 1;
    }
    return {
      idx: idx + 1,
      col: this.colors[idx]
    };
  }
  getStrengthIndexAndColor(password) {
    return this.getColor(NgxPasswordStrengthBarComponent.measureStrength(password));
  }
  ngOnChanges(changes) {
    if (!changes['passwordToCheck']) {
      return;
    }
    const password = changes['passwordToCheck'].currentValue;
    this.checkBarColors();
    this.checkThresholds();
    this.setBarColors(5, this.baseColor);
    let strength = 0;
    if (password) {
      const c = this.getStrengthIndexAndColor(password);
      strength = c.idx - 1;
      this.setStrengthLabel(strength);
      this.setBarColors(c.idx, c.col);
    }
    this.onStrengthChanged.emit(strength);
  }
  setBarColors(count, col) {
    for (let _n = 0; _n < count; _n++) {
      this['bar' + _n] = col;
    }
  }
  setStrengthLabel(index) {
    if (this.strengths) {
      this.strengthLabel = this.strengths[index];
    }
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.2.1",
      ngImport: i0,
      type: NgxPasswordStrengthBarComponent,
      deps: [],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "16.2.1",
      type: NgxPasswordStrengthBarComponent,
      selector: "ngx-password-strength-bar",
      inputs: {
        passwordToCheck: "passwordToCheck",
        barLabel: "barLabel",
        barColors: "barColors",
        baseColor: "baseColor",
        strengthLabels: "strengthLabels",
        customThresholds: "customThresholds"
      },
      outputs: {
        onStrengthChanged: "onStrengthChanged"
      },
      usesOnChanges: true,
      ngImport: i0,
      template: `
    <div id="strength" #strength>
      <small>{{barLabel}}</small>
      <ul id="strengthBar" class="strengthBar">
        <li id="bar0" class="point" [style.background-color]="bar0"></li>
        <li class="point" [style.background-color]="bar1"></li>
        <li class="point" [style.background-color]="bar2"></li>
        <li class="point" [style.background-color]="bar3"></li>
        <li class="point" [style.background-color]="bar4"></li>
      </ul>
      <small [hidden]="!strengths" class="pre">  {{strengthLabel}}</small>
    </div>
  `,
      isInline: true,
      styles: [".strengthBar{display:inline;list-style:none;margin:0 0 0 15px;padding:0;vertical-align:2px}.strengthBar .point{background:#DDD;border-radius:2px;display:inline-block;height:5px;margin-right:1px;width:20px}.strengthBar .point:last-child{margin:0}.pre{white-space:pre}\n"]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.2.1",
  ngImport: i0,
  type: NgxPasswordStrengthBarComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ngx-password-strength-bar',
      template: `
    <div id="strength" #strength>
      <small>{{barLabel}}</small>
      <ul id="strengthBar" class="strengthBar">
        <li id="bar0" class="point" [style.background-color]="bar0"></li>
        <li class="point" [style.background-color]="bar1"></li>
        <li class="point" [style.background-color]="bar2"></li>
        <li class="point" [style.background-color]="bar3"></li>
        <li class="point" [style.background-color]="bar4"></li>
      </ul>
      <small [hidden]="!strengths" class="pre">  {{strengthLabel}}</small>
    </div>
  `,
      styles: [".strengthBar{display:inline;list-style:none;margin:0 0 0 15px;padding:0;vertical-align:2px}.strengthBar .point{background:#DDD;border-radius:2px;display:inline-block;height:5px;margin-right:1px;width:20px}.strengthBar .point:last-child{margin:0}.pre{white-space:pre}\n"]
    }]
  }],
  ctorParameters: function () {
    return [];
  },
  propDecorators: {
    passwordToCheck: [{
      type: Input
    }],
    barLabel: [{
      type: Input
    }],
    barColors: [{
      type: Input
    }],
    baseColor: [{
      type: Input
    }],
    strengthLabels: [{
      type: Input
    }],
    customThresholds: [{
      type: Input
    }],
    onStrengthChanged: [{
      type: Output
    }]
  }
});
class NgxPasswordStrengthBarModule {
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.2.1",
      ngImport: i0,
      type: NgxPasswordStrengthBarModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "16.2.1",
      ngImport: i0,
      type: NgxPasswordStrengthBarModule,
      declarations: [NgxPasswordStrengthBarComponent],
      exports: [NgxPasswordStrengthBarComponent]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "16.2.1",
      ngImport: i0,
      type: NgxPasswordStrengthBarModule
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.2.1",
  ngImport: i0,
  type: NgxPasswordStrengthBarModule,
  decorators: [{
    type: NgModule,
    args: [{
      declarations: [NgxPasswordStrengthBarComponent],
      imports: [],
      exports: [NgxPasswordStrengthBarComponent]
    }]
  }]
});

/*
 * Public API Surface of ngx-password-strength-bar
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxPasswordStrengthBarComponent, NgxPasswordStrengthBarModule };
